import { http } from "./utils/fetch"
import {Image} from 'react-native';
import * as RootNavigation from './RootNavigation';

export const proposalmain = {
    state: {
        loading: true,
        data: {}
    }, // initial state
    reducers: {
        // handle state changes with pure functions
        setMainInfo(state, payload) {
            return {
                data: payload,
                loading: false
            }
        },
    },
    effects: dispatch => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async fetchInfo(mainToken, receiver_id) {
            try {
                // TODO set id
                // if(payload) throw Error('ups')
                
                const {data} = await http.get(mainToken, {receiver_id: receiver_id})
                
                data.list.forEach(estate => {
                    if(estate.photos) {
                        estate.photos.forEach(photo => Image.prefetch(photo))
                    }});
                sessionStorage.setItem('language', data.language)
                dispatch.proposalmain.setMainInfo(data)
            }catch (e) {
                setTimeout(() => {
                    RootNavigation.replace('404', {url: window.location.toString()})
                }, 200)
            }

        },
    }),
}