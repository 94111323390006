import React from 'react'
import { useMediaQuery } from 'react-responsive'
const MediaContext = React.createContext()

function MediaProvider({children}) {
    const isTablet = useMediaQuery({ query: '(max-width: 1023.99px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 425px)' })
    const isLaptop = useMediaQuery({ query: '(max-width: 1024px)' })

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  
    const value = {isTablet, isMobile, isPortrait, isRetina, isLaptop, useMediaQuery }

    return <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
}
function useMedia() {
    const context = React.useContext(MediaContext)
    if (context === undefined) {
      throw new Error('useCount must be used within a CountProvider')
    }
    return context
  }
export {useMedia, MediaProvider}