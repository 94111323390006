import React from "react";

import { Text } from "react-native"
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import loadable from "@loadable/component";

import { navigationRef } from './RootNavigation';
import store from "./store";
import { Provider } from 'react-redux'
import { MediaProvider } from "./media/MediaProvider";

const Main = loadable(() => import('./pages/Main'))
const NotFound = loadable(() => import('./pages/NotFound'))
const PdfOnFlyScreen = loadable(() => import('./pages/PdfOnFlyScreen'))
const Details = loadable(() => import('./pages/Details'))


const Stack = createStackNavigator();
const config = {
  screens: {
    Home: '/:key',
    Details: '/:key/:token/:slug',
    Pdf: '/:key/:token/:slug/pdf'
  },
};

const linking = {
  prefixes: ['http://localhost:9292', 'https://objektai.topbroker.lt', 'https://api.topbroker.lt', 'https://klientams.topbroker.lt', 'tbproposal://'],
  config,
};




function App() {

  return (
    <React.StrictMode>
		<Provider store={store}>
      <MediaProvider>
      <NavigationContainer ref={navigationRef} linking={linking} fallback={<Text>Loading...</Text>}>
        <Stack.Navigator headerMode="screen" screenOptions={{presentation: 'card'}} >
            <Stack.Screen name="404" options={{headerShown: false}} component={NotFound}  />
            <Stack.Screen name="Home" options={{headerShown: false}} component={Main} />
            <Stack.Screen name="Details" options={{headerShown: false}} component={Details} />
            <Stack.Screen name="Pdf" options={{headerShown: false}} component={PdfOnFlyScreen} />

        </Stack.Navigator>
			</NavigationContainer>
      </MediaProvider>
      </Provider>
    </React.StrictMode>
  );
}
export default App;
